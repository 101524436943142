<template>
  <bulma-autocomplete
    :id="id+ '_address'"
    v-model="suburb"
    placeholder="Suburb"
    :data="localities.data"
    field="text"
    @input="mapboxLocalitySearch"
    :loading="localities.isFetching"
    @select="onSuburbSelect($event)"
    :max-results="mapboxMap.limit">
    <template slot-scope="props">
      <div>
        <span class="icon is-small">
          <i class="mdi mdi-map-marker" />
        </span>
        {{ `${props.option.place_name}` }}
      </div>
    </template>
  </bulma-autocomplete>
</template>

<script>
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import MapboxApi from '@/services/mapbox-api'
import _debounce from 'lodash.debounce'
import Neighbours from '@/assets/json/neighbours.json'

export default {
  name: 'MapboxSuburbAutocomplete',
  components: {
    BulmaAutocomplete
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    v: null
  },
  data() {
    return {
      suburb: this.value,
      mapboxMap: {
        places: '/mapbox.places',
        autoComplete: '?autocomplete=true',
        accessToken: 'pk.eyJ1IjoiY2luaXgxIiwiYSI6ImNqYzV1YWR5bTFhd3czM255b3FwdWl5aG0ifQ.G2Llp-QEtZ7V9UKOXv-ASA',
        limit: 6,
        proximity: ''
      },
      suggestions: {
        data: [],
        query: '',
        selected: null,
        isFetching: false
      },
      localities: {
        data: [],
        query: '',
        selected: null,
        isFetching: false
      },
      country: null,
      geoCodingTypes: {
        address: 'address',
        locality: 'locality',
        postcode: 'postcode',
        region: 'region',
        country: 'country'
      },
      countries: []
    }
  },
  computed: {
    countryCodes() {
      let neighbours = Neighbours.find(n => n.countryCode === this.$company.info.countryCode) || []
      return [this.$company.info.countryCode, ...neighbours.neighbours]
    }
  },
  watch: {
    suburb: function(newVal, oldVal) {
      this.$emit('input', newVal)
    }
  },
  created() {
    this.getCountries()
    this.suburb = this.value
  },
  mounted() {
    this.getLocation()
  },
  methods: {
    getLocation() {
      if (navigator.geolocation) {
        const vm = this
        navigator.geolocation.getCurrentPosition(function(position) {
          vm.mapboxMap.proximity = `${position.coords.longitude},${position.coords.latitude}`
        })
      }
    },
    mapboxLocalitySearch: _debounce(async function() {
      if (this.suburb) {
        // this.v.suburbText.$touch()
        this.localities.data = []
        this.localities.isFetching = true
        const url = `${this.mapboxMap.places}/${this.suburb}.json?access_token=${this.mapboxMap.accessToken}&country=${this.countries.join(',')}&proximity=${this.mapboxMap.proximity}&types=${this.geoCodingTypes.locality}&${this.mapboxMap.autoComplete}&limit=${this.mapboxMap.limit}`
        const result = await MapboxApi.get(url)
        const vm = this
        result.data.features.map(function(f) {
          // Customise dropdown display suggestions
          const state = f.context.find(context => context.id.startsWith(vm.geoCodingTypes.region)).text
          const postcode = f.context.find(context => context.id.startsWith(vm.geoCodingTypes.postcode)).text
          const country = f.context.find(context => context.id.startsWith(vm.geoCodingTypes.country)).text
          f.place_name = `${f.text}, ${state} ${postcode}, ${country}`
          vm.localities.data.push(f)
        })
        this.localities.isFetching = false
      }
    }, 500),
    onSuburbSelect(event) {
      if (event) {
        // this.v.$touch()
        this.localities.selected = event
        // const contexts = this.localities.selected.context
        // const countryData = contexts.find(context => context.id.startsWith(this.geoCodingTypes.country))
        // const stateData = contexts.find(context => context.id.startsWith(this.geoCodingTypes.region))
        // this.address.suburbPostcode = contexts.find(context => context.id.startsWith(this.geoCodingTypes.postcode)).text
        // this.address.stateCode = stateData.short_code.split('-')[1]
        // this.address.stateText = stateData.text
        // this.address.countryText = countryData.text
        // this.address.countryCode = countryData.short_code.toUpperCase()
      }
    },
    getCountries() {
      let neighbours = Neighbours.find((n) => n.countryCode === this.$company.info.countryCode) || []
      this.countries = [this.$company.info.countryCode, ...neighbours.neighbours]
    }
  }
}
</script>
